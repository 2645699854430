import React from "react";
import "./App.css";
// import Navbar from "./components/NavBar/Navbar";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./components/pages/Home";
import Services from "./components/pages/OurServices";
import AboutUs from "./components/pages/AboutUs";
// import Works from "./components/pages/Works";
import Blogs from "./components/pages/Blog/Components/Blogs";
import DetailedServices from "./components/pages/DetailedServices";
import Footer from "./components/Footer/Footer";
import ScrollToTop from "./components/Works/ScrollToTop";
import Mailer from "./components/ContactUs/Mailer";

function App() {
  return (
    <>
      <Router>
        <ScrollToTop />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/services" exact component={Services} />
          <Route path="/aboutus" exact component={AboutUs} />
          {/* <Route path="/works" exact component={Works} /> */}
          <Route path="/blogs" exact component={Blogs} />
          <Route path="/detailed-services" exact component={DetailedServices} />
          <Route path="/footer" exact component={Footer} />
          <Route path="/contact-us" exact component={Mailer} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
