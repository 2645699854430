import React from "react";
// import "../../App.css";
//import { Button } from './Button'
import "./HeroSection.css";
// import { FliesText } from "react-text-fun";
// import { LiquidDistortionText } from "react-text-fun";
import ReactTypingEffect from "react-typing-effect";

import Particles from "react-tsparticles";
import MouseParticles from "react-mouse-particles";

import { motion } from "framer-motion";
import Loader from "./Loader/Loader";

function HeroSection() {
  return (
    <div id="home" className="hero-container">
      <Particles
        className="particles"
        id="tsparticles"
        options={{
          // background: {
          // 	color: {
          // 		value: "#3c3b9b",
          // 	},
          // },
          fpsLimit: 60,

          //interactivity starts
          interactivity: {
            detectsOn: "canvas",
            events: {
              onHover: {
                enable: true,
                mode: "repulse",
              },
              resize: true,
            },
            modes: {
              bubble: {
                distance: 400,
                duration: 2,
                opacity: 0.8,
                size: 40,
              },

              repulse: {
                distance: 100,
                duration: 0.4,
              },
            },
          },

          //inractivity ends

          particles: {
            color: "#8888ff",

            links: {
              color: "random",
              distance: 150,
              enable: true,
              opacity: 1,
              width: 2,
            },

            collisions: {
              enable: true,
            },
            move: {
              direction: "none",
              enable: true,
              outMode: "bounce",
              random: true,
              speed: 2,
              straight: false,
            },
            number: {
              density: {
                enable: true,
                value_area: 1000,
              },
              value: 100,
            },
            opacity: {
              value: 0.5,
            },
            shape: {
              type: "circle",
            },
            size: {
              random: false,
              value: 3,
            },
          },
          detectRetina: true,
        }}
      />
      <motion.div
        className="title"
        initial={{ y: -400 }}
        animate={{ y: 0 }}
        transition={{
          delay: 0.2,
          type: "spring",
          stiffness: 200,
        }}
      >
        <h1>STERNX</h1>
      </motion.div>

      {/* <p>where the solution exists</p> */}
      {/* https://www.npmjs.com/package/react-typing-effect */}
      {/* <ReactTypingEffect
        speed={100} // default 500ms. Typing speed.
        eraseSpeed={80} // default 500ms. Erase speed.
        eraseDelay={1250} // default 5000ms. Time to wait before erasing the text.
        typingDelay={600}
        cursorClassName={"cursor"}
        cursor={"|"} // Default: |
        text={["where the solution exists"]}
        cursorRenderer={(cursor) => <p>{cursor}</p>}
        displayTextRenderer={(text, i) => {
          return (
            <h1>
              {text.split("").map((char, i) => {
                const key = `${i}`;
                return (
                  <span
                    key={key}
                    style={
                      i % 2 === 0 ? { color: "white" } : { color: "white" }
                    }
                  >
                    {char}
                  </span>
                );
              })}
            </h1>
          );
        }}
      /> */}

      <MouseParticles
        g={2.3}
        num={1}
        radius={8}
        life={0.8}
        v={1.2}
        color="random"
        //color={["#ff0000", "#ccdfs2"]}
        alpha={0.16}
        level={6}
      />
      {/* <div className="flies-text-title">
        <FliesText
          text="STERNX"
          fontSize={130}
          fontWeight="bold"
          cellWidth={0.02}
          fill="#ffffff"
          speed={2}
        />
      </div> */}
      {/* <div className="flies-text-title">

        <FliesText
          text="STERNX"
          fontSize={150}
          fontWeight="bold"
          cellWidth={0.013}
          fill="#ffffff"
          speed={1}
        />
      </div> */}
      {/* <div className="flies-text-slogan">
        <LiquidDistortionText
          text="where the solution exists"
          fontSize={45}
          speed={0.6}
          volatility={0.02}
          fill="#f0f5f5"
        />
      </div> */}
      <br></br>
      <div className="typing-text-slogan">
        <ReactTypingEffect
          speed={100} // default 500ms. Typing speed.
          eraseSpeed={80} // default 500ms. Erase speed.
          eraseDelay={1250} // default 5000ms. Time to wait before erasing the text.
          typingDelay={600}
          cursorClassName={"cursor"}
          cursor={"|"} // Default: |
          text={["where the solution exists"]}
          cursorRenderer={(cursor) => <p>{cursor}</p>}
          displayTextRenderer={(text, i) => {
            return (
              <h1>
                {text.split("").map((char, i) => {
                  const key = `${i}`;
                  return (
                    <span
                      key={key}
                      style={
                        i % 2 === 0
                          ? { color: "white", fontFamily: "Times New Roman" }
                          : { color: "white", fontFamily: "Times New Roman" }
                      }
                    >
                      {char}
                    </span>
                  );
                })}
              </h1>
            );
          }}
        />
      </div>
      {/* <Loader /> */}
    </div>
  );
}

export default HeroSection;

/*
import React from "react";
import "../App.css";
//import { Button } from './Button'
import "./HeroSection.css";
import Particles from "react-tsparticles";

function HeroSection() {
  return (
    <div className="hero-container">
      <Particles
        className="particles"
        id="tsparticles"
        options={{
          background: {
            color: {
              value: "#000000",
            },
          },
          fpsLimit: 60,

          //interactivity starts
          interactivity: {
            detectsOn: "canvas",
            events: {
              onClick: {
                enable: true,
                mode: "push",
              },

              onHover: {
                enable: true,
                mode: "repulse",
              },
              resize: true,
            },
            modes: {
              bubble: {
                distance: 400,
                duration: 2,
                opacity: 0.8,
                size: 40,
              },
              push: {
                quantity: 4,
              },
              repulse: {
                distance: 100,
                duration: 0.4,
              },
            },
          },

          //inractivity ends

          particles: {
            color: "random",

            links: {
              color: "random",
              distance: 150,
              enable: true,
              opacity: 1,
              width: 1.5,
            },

            collisions: {
              enable: true,
            },
            move: {
              direction: "none",
              enable: true,
              outMode: "bounce",
              random: false,
              speed: 3,
              straight: false,
            },
            number: {
              density: {
                enable: true,
                value_area: 800,
              },
              value: 80,
            },
            opacity: {
              value: 0.5,
            },
            shape: {
              type: "circle",
            },
            size: {
              random: true,
              value: 5,
            },
          },
          detectRetina: true,
        }}
      />

      <h1>STERNX</h1>
      <p>where the solution exists</p>
    </div>
  );
}

export default HeroSection;

*/

/* previous interactivity section
          interactivity: {
 detectsOn: "canvas",
            events: {
              onClick: {
                enable: true,
                mode: "push",
              },

              onHover: {
                enable: true,
                mode: "repulse",
              },
              resize: true,
            },
            modes: {
              bubble: {
                distance: 400,
                duration: 2,
                opacity: 0.8,
                size: 40,
              },
              push: {
                quantity: 4,
              },
              repulse: {
                distance: 100,
                duration: 0.4,
              },
            },
          },

*/

/*
import React from "react";
import "../App.css";
//import { Button } from './Button'
import "./HeroSection.css";
import Particles from "react-particles-js";

function HeroSection() {
  return (
    <div className="hero-container">
      <Particles
        className="particles"
        params={{
          particles: {
            number: {
              value: 50,
            },
            size: {
              value: 3,
            },
          },
          interactivity: {
            events: {
              onhover: {
                enable: true,
                mode: "repulse",
              },
            },
          },
        }}
      />

      <h1>STERNX</h1>
      <p>where the solution exists</p>
    </div>
  );
}

export default HeroSection;
*/

/*
import React from "react";
import "../App.css";
//import { Button } from './Button'
import "./HeroSection.css";

function HeroSection() {
  return (
    <div className="hero-container">
      <video src="/videos/video-5.mp4" autoPlay loop muted />
      <h1>STERN X</h1>
      <p>Where the solution exists</p>
      <div className="hero-btns">
        </div>
        </div>
      );
    }
    
    export default HeroSection;
    
*/

/*

<div className="hero-container">
      <Particles
        params={{
          particles: {
            number: {
              value: 200,
              density: {
                enable: true,
                value_area: 1000,
              },
            },
          },
        }}
      />
      <h1>STERN X</h1>
      <p>Where the solution exists</p>
    </div>
*/

/*
import React from "react";
import "../App.css";
//import { Button } from './Button'
import "./HeroSection.css";
import Particles from "react-particles-js";


function HeroSection() {
  return (
    <>
    
    <div className="hero-container">
    
      <Particles
        className="particles"
        params={{
          particles: {
            number: {
              value: 150,
            },
            size: {
              value: 5,
            },
          },
          interactivity: {
            events: {
              onhover: {
                enable: true,
                mode: "repulse",
              },
            },
          },
        }}
      />

      <h1>STERNX</h1>
      <p>where the solution exists</p>
      
    </div>
    
    </>
  );
}

export default HeroSection;
*/

/*
import React from "react";
import "../App.css";
//import { Button } from './Button'
import "./HeroSection.css";

function HeroSection() {
  return (
    <div className="hero-container">
      <video src="/videos/video-5.mp4" autoPlay loop muted />
      <h1>STERN X</h1>
      <p>Where the solution exists</p>
      <div className="hero-btns">
        </div>
        </div>
      );
    }
    
    export default HeroSection;
    
*/

/*

<div className="hero-container">
      <Particles
        params={{
          particles: {
            number: {
              value: 200,
              density: {
                enable: true,
                value_area: 1000,
              },
            },
          },
        }}
      />
      <h1>STERN X</h1>
      <p>Where the solution exists</p>
    </div>
*/
