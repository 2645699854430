import React from "react";
import Popup from "reactjs-popup";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
// import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
});

function CardItem(props) {
  const classes = useStyles();
  return (
    <>
      <Popup
        className="cards__item__link"
        trigger={
          <li className="cards__item">
            <div className="cards__item__link">
              <figure
                className="cards__item__pic-wrap"
                data-category={props.label}
              >
                <img src={props.src} alt="" className="cards__item__img" />
              </figure>
              <div className="cards__item__info">
                <h5 className="cards__item__text">{props.text}</h5>
              </div>
            </div>
          </li>
        }
        position="center"
      >
        <div>
          <Card className={classes.root}>
            <CardActionArea>
              {/*
              <CardMedia
                component="img"
                alt=""
                height="140"
                image={props.src}
                title={props.text}
              */}
              <CardContent className="card-pop">
                <Typography
                  align="center"
                  gutterBottom
                  variant="h5"
                  component="h2"
                >
                  {props.text}
                </Typography>
                <Typography
                  align="center"
                  variant="body2"
                  color="textSecondary"
                  component="p"
                >
                  {props.description}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </div>
      </Popup>
    </>
  );
}

export default CardItem;

/*
import React from "react";
import { Link } from "react-router-dom";

function CardItem(props) {
  return (
    <>
      <li className="cards__item">
        <Link className="cards__item__link" to={props.path}>
          <figure className="cards__item__pic-wrap" data-category={props.label}>
            <img src={props.src} alt="" className="cards__item__img" />
          </figure>
          <div className="cards__item__info">
            <h5 className="cards__item__text">{props.text}</h5>
          </div>
        </Link>
      </li>
    </>
  );
}

export default CardItem;
*/
