import "./Mailer.css";
import emailjs from "emailjs-com";
import MouseParticles from "react-mouse-particles";
import MessengerCustomerChat from "react-messenger-customer-chat";
const Mailer = () => {
  function sendEmail(e) {
    e.preventDefault();

    //copied from emailjs site - service id, template id, user id
    emailjs
      .sendForm(
        "service_vyuyi1n",
        "template_eiasj2r",
        e.target,
        "user_t2efOt7xvApvq3m1bGqDo"
      )
      .then((res) => {
        console.log(res);
        e.target.reset();
      })
      .catch((err) => console.log(err));
  }

  return (
    <>
      
      <div className="mail-container">
        <h1>Contact Us</h1>
        <hr></hr>
        <p>We'd like to hear from you !</p>
        <br></br>
        <br></br>
        <form onSubmit={sendEmail}>
          {/* <label>Your Name</label> */}
          {/* <input type="text" id="name" name="name" placeholder="Your name.." /> */}
          <input type="text" id="name" name="name" placeholder="Your Name" />

          {/* <label>Your Email</label> */}
          {/* <input
            type="text"
            id="email"
            name="email"
            placeholder="Your email.."
          /> */}
          <input type="text" id="email" name="email" placeholder="Your Email" />

          {/* <label>Message</label> */}
          {/* <textarea
            id="message"
            name="message"
            placeholder="Enter your message here.."
            style={{ height: "150px" }}
            defaultValue={""}
            size="5"
          /> */}
          <textarea
            id="message"
            name="message"
            placeholder="Message"
            style={{ height: "150px" }}
            defaultValue={""}
            size="5"
          />
          <input type="submit" value="Send Message" />
        </form>
        <MouseParticles
          g={2.3}
          num={1}
          radius={8}
          life={0.8}
          v={1.2}
          color="random"
          //color={["#ff0000", "#ccdfs2"]}
          alpha={0.16}
          level={6}
        />
      </div>
      <MessengerCustomerChat
        pageId="104623278386310"
        appId="3142755355947250"
      />
    </>
  );
};

export default Mailer;
