import React from "react";
// import { Link } from "react-router-dom";

function ServiceItemRight(props) {
  return (
    <>
      <div className="card">
        <div className="row">
          <div className="column">
            <p>
              <br></br>
              <h2>{props.serviceName}</h2>
              <hr />
              {props.description}
            </p>
          </div>
          <div className="column">
            <img
              src={props.src}
              width="400"
              height="300"
              className="cards__item__info"
              alt=""
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default ServiceItemRight;

/*
import React from "react";
// import { Link } from "react-router-dom";

function ServiceItemLeft(props) {
  return (
    <>
      <table>
        <tr>
          <td>
            <p>
              <h2>{props.serviceName}</h2>
              <hr />
              {props.description}
            </p>
          </td>
          <td>
            <img
              src={props.src}
              width="400"
              height="300"
              className="cards__item__info"
              alt=""
            />
          </td>
        </tr>
      </table>
    </>
  );
}

export default ServiceItemLeft;
*/

/*
import React from "react";
// import { Link } from "react-router-dom";

function ServiceItemLeft(props) {
  return (
    <>
      <table>
        <tr>
          <td>
            <p>
              <h2>{props.serviceName}</h2>
              <hr />
              {props.description}
            </p>
          </td>
          <td>
            <img
              src={props.src}
              width="400"
              height="300"
              className="cards__item__info"
              alt=""
            />
          </td>
        </tr>
      </table>
    </>
  );
}

export default ServiceItemLeft;
*/
