import React from "react";
import { Grid } from "@material-ui/core";
import Blog from "./Blog";
import axios from "axios";
import Footer from "../../../Footer/Footer";
import Navbar from "../../../NavBar/Navbar";
import MouseParticles from "react-mouse-particles";
import MessengerCustomerChat from "react-messenger-customer-chat";
import LoadingComponent from "./loadingComponent";
// wrapper for items

const URLS = [
  "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@RusiruThushara",
  "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@NuwanHarshakumaraPiyarathna",
  "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@sudamkalpage4",
  "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@hashaneranga",
  "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@thilini-deshika",
];

// Add titles of must removed articles here
// ex :
// const POSTS_TO_REMOVE = ["Spring Boot PUT Request with MongoDB"];
const POSTS_TO_REMOVE = [];

class Blogs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      postList: [],
      loading: true,
    };
  }

  // This URL can be renamed by our user names in the medium article ... I put a random name
  //  "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@<username>";

  componentDidMount() {
    const posts = [];
    URLS.map((oneUrl, index) => {
      axios.get(oneUrl).then((res) => {
        res.data.items.map((onePost) => {
          onePost.avatar = res.data.feed.image;
          onePost.profilelink = res.data.feed.link;
          if (!POSTS_TO_REMOVE.includes(onePost.title)) {
            posts.push(onePost);
          }
        });
        posts.sort((a, b) => (a.pubDate > b.pubDate ? 1 : -1));
        if (index === URLS.length - 1) {
          this.setState({ postList: posts }, () => {
            this.setState({ loading: false });
          });
        } else {
          this.setState({ postList: posts });
        }
      });
    });
  }

  render() {
    if (this.state.loading === true)
      return <LoadingComponent></LoadingComponent>;
    return (
      <div style={{ backgroundColor: "#05051f" }}>
        <Navbar />
        <Grid container spacing={1}>
          {this.state.postList.map((data, key) => {
            console.log(data.pubDate);
            return <Blog item={data} key={key} />;
          })}
        </Grid>
        <br></br>
        <MouseParticles
          g={2.3}
          num={1}
          radius={8}
          life={0.8}
          v={1.2}
          color="random"
          //color={["#ff0000", "#ccdfs2"]}
          alpha={0.16}
          level={6}
        />
        <Footer></Footer>
        <MessengerCustomerChat
          pageId="104623278386310"
          appId="3142755355947250"
        />
      </div>
    );
  }
}
export default Blogs;
