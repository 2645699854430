import React from "react";
//import "../../App.css";
//import "../AboutUs/AboutSection.css";
import "../AboutUs/AboutUs.css";
import AboutCard from "../AboutUs/AboutCard";
import Image from "../AboutUs/Image";

//import MissionVisionItem from "../AboutUs/MissionVisionItem";

const cards1 = [
  {
    title: "🚀 Our Mission",
    description:
      "Our mission is to provide Secure Trustworthy Elegant Reliable Neat products and services for the clients.",
    image: "images/aboutus_images/about2.svg",
    imageRatio: 1800 / 2000,
  },
];

const cards2 = [
  {
    title: "About Us",
    description:
      "We are a software development team in Sri Lanka having over two years of experience in the field. Our focus is to understand the unique requirements of each client and meet them with their expected product while ensuring customer satisfaction. Our team with excellent problem-solving skills and commitment towards the end goal is here to steer you to the software solution that you need to digitalize your business entities with the updating cutting edge technologies.",
    image: "images/aboutus_images/about3.svg",
    imageRatio: 1500 / 2000,
  },
];

const cards3 = [
  {
    title: "Our Vision ⚡️",
    description:
      "Our vision is seeing a society experiencing sustainable reliable secure trustworthy elegant neat products and services from us to make their day to day life easier and comfortable.",
    image: "images/aboutus_images/about1.svg",
    imageRatio: 1500 / 2000,
  },
];

export default function AboutUs() {
  return (
    <>
      <div id="aboutus" className="about-section">
        <div className="container">
          <div className="row">
            {cards1.map((card, i) => (
              <div className="column-small">
                <AboutCard>
                  <div className="card-title">{card.title}</div>
                  <div className="card-body">{card.description}</div>
                  <Image ratio={card.imageRatio} src={card.image} />
                </AboutCard>
              </div>
            ))}
            {cards2.map((card, i) => (
              <div className="column">
                <AboutCard>
                  <div className="card-title">{card.title}</div>
                  <div className="card-body">{card.description}</div>
                  <Image ratio={card.imageRatio} src={card.image} />
                </AboutCard>
              </div>
            ))}
            {cards3.map((card, i) => (
              <div className="column-small">
                <AboutCard>
                  <div className="card-title">{card.title}</div>
                  <div className="card-body">{card.description}</div>
                  <Image ratio={card.imageRatio} src={card.image} />
                </AboutCard>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

/*
import React from "react";
//import "../../App.css";
//import "../AboutUs/AboutSection.css";
import "../AboutUs/AboutUs.css";
import AboutCard from "../AboutUs/AboutCard";
import Image from "../AboutUs/Image";

//import MissionVisionItem from "../AboutUs/MissionVisionItem";

const cards = [
  {
    title: "🚀 Our Mission",
    description:
      "Our mission is to provide Secure Trustworthy Elegant Reliable Neat Products and Services for the Clients.",
    image: "images/aboutus_images/about2.svg",
    imageRatio: 1500 / 1016,
  },

  {
    title: "About Us",
    description:
      "We are a software development team in Sri Lanka having over two years of experience in the field. Our focus is to understand the unique requirements of each client and meet them with their expected product while ensuring customer satisfaction. Our team with excellent problem-solving skills and commitment towards the end goal is here to steer you to the software solution that you need to digitalize your business entities with the updating cutting edge technologies.",
    image: "images/aboutus_images/about3.svg",
    imageRatio: 835 / 1133,
  },

  {
    title: "Our Vision ⚡️",
    description:
      "Our Vision is seeing a society experiencing sustainable reliable secure trustworthy elegant neat products and services from us to make their day to day life easier and comfortable.",
    image: "images/aboutus_images/about1.svg",
    imageRatio: 1400 / 1030,
  },
];

export default function AboutUs() {
  return (
    <>
      <div id="aboutus" className="about-section">
        <div className="container">
          <div className="row">
            {cards.map((card, i) => (
              <div className="column">
                <AboutCard>
                  <div className="card-title">{card.title}</div>
                  <div className="card-body">{card.description}</div>
                  <Image ratio={card.imageRatio} src={card.image} />
                </AboutCard>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
*/

/*
import React from "react";
import "../../App.css";
import "../AboutUs/AboutSection.css";
import MissionVisionItem from "../AboutUs/MissionVisionItem";

export default function AboutUs() {
  return (
    <>
      <div className="about-section">
        <div className="about-section-container">
          <div className="about-section-container-title">
            <h1>About Us </h1>
          </div>

          <h4>
            We are a software development team in Sri Lanka having over two
            years of experience in the field. Our focus is to understand the
            unique requirements of each client and meet them with their expected
            product while ensuring customer satisfaction. Our team with
            excellent problem-solving skills and commitment towards the end goal
            is here to steer you to the software solution that you need to
            digitalize your business entities with the updating cutting edge
            technologies.
          </h4>
        </div>

        <br></br>
        <br></br>
        <br></br>
        <ul className="mission__items">
          <MissionVisionItem
            topic="Our Mission"
            text="Our mission is to provide 
            Secure 
            Trustworthy 
            Elegant
            Reliable 
            Neat Products and Services for the Clients."
          />
          <MissionVisionItem
            topic="Our Vision"
            text="Our Vision is seeing a society experiencing sustainable reliable secure trustworthy elegant neat products and services from us to make their day to day life easier and comfortable."
          />
        </ul>
      </div>
    </>
  );
}
*/
