import React from "react";
import "../../App.css";
import Footer from "../Footer/Footer";
import ServiceItemLeft from "../OurServices/ServiceItemLeft";
import ServiceItemRight from "../OurServices/ServiceItemRight";

export default function DetailedServices() {
  return (
    <>
      <div className="service-section">
        <div className="cards__container">
          <div className="cards__wrapper">
            <ul className="cards__items">
              <ServiceItemLeft
                src="images/img-services-1/serviceedit1.png"
                serviceName="Mobile Application Development"
                description="Smartphones have become the most common digital assistant for humans. We will help your ideas to reach customers with the fast-growing mobile application trends. A mobile application compatible with both Android and iOS operating systems will be delivered to you with user attractive features and optimized functionalities.
                "
              />
            </ul>

            <ul className="cards__items">
              <ServiceItemRight
                src="images/img-services-1/serviceedit2.png"
                serviceName="Website Development"
                description="Let your business be accessible to anyone, anywhere, anytime with a unique and attractive website. Our modern responsive web designs and up to date web development capabilities are delivered to you with expert services. A creative website helps to establish the credibility of your business with the help of the latest technologies. 

                "
              />
            </ul>
            <ul className="cards__items">
              <ServiceItemLeft
                src="images/img-services-1/serviceedit3.png"
                serviceName="API Development"
                description="API, Application Programming Interface will help other software applications to communicate with your software app or platform while establishing better services. Our API development service is here to simplify and speed up your existing software service, by acting as a layer in between software and hiding complexities within individual services.
                "
              />
            </ul>

            <ul className="cards__items">
              <ServiceItemRight
                src="images/img-services-1/serviceedit4.png"
                serviceName="Game Development"
                description="The gaming industry plays a major role in technology because of its impact on advancing entertainment and culture. Whether you want to create a game as a mobile application or a web application with enhanced features, we will bring your game idea to the front of worldwide gamers with interactive features and an exciting gaming experience.
                "
              />
            </ul>
            <ul className="cards__items">
              <ServiceItemLeft
                src="images/img-services-1/serviceedit5.png"
                serviceName="UI / UX Services"
                description="Well made and balanced UI/UX designs helps users to easily interact with your services while enhancing customer satisfaction and loyalty.  We will bring the best-suited UI/UX design to you featuring the usability and accessibility of your service.
                "
              />
            </ul>

            <ul className="cards__items">
              <ServiceItemRight
                src="images/img-services-1/serviceedit6.png"
                serviceName="AI and Machine Learning"
                description="Integrate AI and Machine Learning technologies into your business to obtain actionable insights and drive critical decisions. We will give life to your innovative products and services ideas with these emerging technologies. Migrating your business into  AI and ML-enabled systems will bring it to the advancement in the industry. 
                "
              />
            </ul>
            <ul className="cards__items">
              <ServiceItemLeft
                src="images/img-services-1/seviceedit7.png"
                serviceName="Data Analysis"
                description="Your business data in itself is merely facts and figures. We are here to help you in exploring your data in order to identify your business insights. Our data analysis consists of organizing,  interpreting, illustrating, evaluating and presenting the data with a systematical approach.
                "
              />
            </ul>

            <ul className="cards__items">
              <ServiceItemRight
                src="images/img-services-1/serviceedit8.png"
                serviceName="Cloud Computing"
                description="Cloud computing services are there to provide computing services for your business from applications to storage and processing power. The on-demand availability enables your business to communicate and share more easily outside of the traditional methods. We are here to assist you in designing, developing and managing AWS, Google Cloud and Azure cloud computing services in your production environment.
                "
              />
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

/*
import React from "react";
import "../../App.css";
import Footer from "../Footer";
import ServiceItemLeft from "../ServiceItemLeft";
import ServiceItemRight from "../ServiceItemRight";

export default function DetailedServices() {
  return (
    <>
      <div className="cards">
        <div className="cards__container">
          <div className="cards__wrapper">
            <ul className="cards__items">
              <ServiceItemLeft
                src="images/img-services-1/4678858.jpg"
                serviceName="iOS and Android Mobile Application Development"
                description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
              />
            </ul>

            <ul className="cards__items">
              <ServiceItemRight
                src="images/img-services-2.jpeg"
                serviceName="Web Site Development"
                description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
              />
            </ul>
            <ul className="cards__items">
              <ServiceItemLeft
                src="images/img-services-3.jpeg"
                serviceName="API Development"
                description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
              />
            </ul>

            <ul className="cards__items">
              <ServiceItemRight
                src="images/img-services-5.jpeg"
                serviceName="Game Development"
                description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
              />
            </ul>
            <ul className="cards__items">
              <ServiceItemLeft
                src="images/img-services-4.jpeg"
                serviceName="UI / UX Services"
                description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
              />
            </ul>

            <ul className="cards__items">
              <ServiceItemRight
                src="images/img-services-5.jpeg"
                serviceName="AI and Machine Learning"
                description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
              />
            </ul>
            <ul className="cards__items">
              <ServiceItemLeft
                src="images/img-services-6.jpeg"
                serviceName="Data Analysis"
                description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
              />
            </ul>

            <ul className="cards__items">
              <ServiceItemRight
                src="images/img-services-3.jpeg"
                serviceName="Cloud Computing"
                description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
              />
            </ul>
          </div>
        </div>
      </div>
      <Footer/>
    </>
  );
}
*/
