import React from "react";
// import { Link } from "react-router-dom";
import "./ServiceItem.css";

function ServiceItemLeft(props) {
  return (
    <>
      <div className="card">
        <div className="row">
          <div className="column">
            <img
              src={props.src}
              width="400"
              height="300"
              className="cards__item__info"
              alt=""
            />
          </div>
          <div className="column">
            <p>
              <br></br>
              <h2>{props.serviceName}</h2>
              <hr />
              {props.description}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default ServiceItemLeft;

/*
import React from "react";
// import { Link } from "react-router-dom";

function ServiceItemLeft(props) {
  return (
    <>
      <div>
        <table>
          <tr>
            <td>
              <img
                src={props.src}
                width="400"
                height="300"
                className="cards__item__info"
                alt=""
              />
            </td>

            <td>
              <p>
                <h2>{props.serviceName}</h2>
                <hr />
                {props.description}
              </p>
            </td>
          </tr>
        </table>
      </div>
    </>
  );
}

export default ServiceItemLeft;

*/

/*
import React from "react";
// import { Link } from "react-router-dom";

function ServiceItemLeft(props) {
  return (
    <>
      <table>
        <tr>
          <td>
            <img
              src={props.src}
              width="400"
              height="300"
              className="cards__item__info"
              alt=""
            />
          </td>

          <td>
            <p>
              <h2>{props.serviceName}</h2>
              <hr />
              {props.description}
            </p>
          </td>
        </tr>
      </table>
    </>
  );
}

export default ServiceItemLeft;
*/
