import React from "react";
import "./Technologies.css";
import "./TechCss.css";

function Technologies() {
  return (
    <>
      <div class="container-fluid">
      <h1>Our Technology Base</h1>
            <hr />
            <br></br>
      <div class="row spec">
        <div class="tech-card col-lg-2 col-md-3 col-sm-2 col-2">
            <img src="images/img/aws.png" alt="" class="techImage"/>
        </div>
        <div class="tech-card col-lg-2 col-md-3 col-sm-2 col-2">
            <img src="images/img/springboot.png" alt="" class="techImage"/>
        </div>
        <div class="tech-card col-lg-2 col-md-3 col-sm-2 col-2">
            <img src="images/img/swift.png" alt="" class="techImage"/>
        </div>
        <div class="tech-card col-lg-2 col-md-3 col-sm-2 col-2">
            <img src="images/img/vue.png" alt="" class="techImage"/>
        </div>
        <div class="tech-card col-lg-2 col-md-3 col-sm-2 col-2">
            <img src="images/img/android.png" alt="" class="techImage"/>
        </div>
        <div class="tech-card col-lg-2 col-md-3 col-sm-2 col-2">
            <img src="images/img/bootstrap.png" alt="" class="techImage"/>
        </div>
        <div class="tech-card col-lg-2 col-md-3 col-sm-2 col-2">
            <img src="images/img/css3.png" alt="" class="techImage"/>
        </div>
        <div class="tech-card col-lg-2 col-md-3 col-sm-2 col-2">
            <img src="images/img/dart.png" alt="" class="techImage"/>
        </div>
        <div class="tech-card col-lg-2 col-md-3 col-sm-2 col-2">
            <img src="images/img/django.png" alt="" class="techImage expImage"/>
        </div>
        <div class="tech-card col-lg-2 col-md-3 col-sm-2 col-2">
            <img src="images/img/docker.png" alt="" class="techImage"/>
        </div>
        <div class="tech-card col-lg-2 col-md-3 col-sm-2 col-2">
            <img src="images/img/expressjs.png" alt="" class="techImage expImage"/>
        </div>
        <div class="tech-card col-lg-2 col-md-3 col-sm-2 col-2">
            <img src="images/img/firebase.png" alt="" class="techImage"/>
        </div>
        <div class="tech-card col-lg-2 col-md-3 col-sm-2 col-2">
            <img src="images/img/flask.png" alt="" class="techImage"/>
        </div>
        <div class="tech-card col-lg-2 col-md-3 col-sm-2 col-2">
            <img src="images/img/flutter.png" alt="" class="techImage expImage"/>
        </div>
        <div class="tech-card col-lg-2 col-md-3 col-sm-2 col-2">
            <img src="images/img/html5.png" alt="" class="techImage"/>
        </div>
        <div class="tech-card col-lg-2 col-md-3 col-sm-2 col-2">
            <img src="images/img/java.png" alt="" class="techImage"/>
        </div>
        <div class="tech-card col-lg-2 col-md-3 col-sm-2 col-2">
            <img src="images/img/js.png" alt="" class="techImage"/>
        </div>
        <div class="tech-card col-lg-2 col-md-3 col-sm-2 col-2">
            <img src="images/img/keras.png" alt="" class="techImage expImage"/>
        </div>
        <div class="tech-card col-lg-2 col-md-3 col-sm-2 col-2">
            <img src="images/img/mongodb.png" alt="" class="techImage expImage"/>
        </div>
        <div class="tech-card col-lg-2 col-md-3 col-sm-2 col-2">
            <img src="images/img/mysql.png" alt="" class="techImage"/>
        </div>
        <div class="tech-card col-lg-2 col-md-3 col-sm-2 col-2">
            <img src="images/img/nodejs.png" alt="" class="techImage"/>
        </div>
        <div class="tech-card col-lg-2 col-md-3 col-sm-2 col-2">
            <img src="images/img/php.png" alt="" class="techImage"/>
        </div>
        <div class="tech-card col-lg-2 col-md-3 col-sm-2 col-2">
            <img src="images/img/python.png" alt="" class="techImage"/>
        </div>
        <div class="tech-card col-lg-2 col-md-3 col-sm-2 col-2">
            <img src="images/img/react.png" alt="" class="techImage"/>
        </div>
        </div>
        </div>
        <div className="techImagesContainer">
          <img src="images/img/tech/techs.svg" alt=""/>
        </div>
    </>
  );
}

export default Technologies;
