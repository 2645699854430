import React from 'react';
import './Footer.css';
// import { Button } from './Button';
// import { Link } from 'react-router-dom';

function Footer() {
  return (
    <>
    <div className="footer-container">    
    <div class="social_links">
        <a href="https://www.facebook.com/sternxengineering">
            <span class="fa-stack fa-lg fb combo">
                <i class="fa fa-circle fa-stack-2x circle"></i>
                <i class="fa fa-facebook fa-stack-1x fa-inverse icon"></i>
              </span>
        </a>
        <a href="https://www.youtube.com/channel/UCc0DmPIO-S5TGd4m4VzdPJw">
            <span class="fa-stack fa-lg yt combo">
                <i class="fa fa-circle fa-stack-2x circle"></i>
                <i class="fa fa-youtube-play fa-stack-1x fa-inverse icon"></i>
              </span>
        </a>
        <a href="/">
            <span class="fa-stack fa-lg tw combo">
                <i class="fa fa-circle fa-stack-2x circle"></i>
                <i class="fa fa-twitter fa-stack-1x fa-inverse icon"></i>
            </span>
        </a>
        <a href="https://www.linkedin.com/company/76945900/admin/">
            <span class="fa-stack fa-lg tw combo">
                <i class="fa fa-circle fa-stack-2x circle"></i>
                <i class="fa fa-linkedin fa-stack-1x fa-inverse icon"></i>
            </span>
        </a>
        <div className="social-media-wrap">
        
    {/* <div class='footer-logo'>
             <Link to='/' className='social-logo'>
             <img src="logo_transparent.png" alt="" width="5%" height="5%"/>
               STERNX
             </Link>
    </div> */}
    <div className="footer-links">
      <p class='website-rights' >STERNX © 2021</p>
    </div>  
    </div>
    </div>
    </div>
    </>
    // <div className='footer-container'>
      
    //     <div class='social-media-wrap'>
    //       <div class='footer-logo'>
    //         <Link to='/' className='social-logo'>
    //           STERNX
    //         </Link>
    //       </div>
    //       <small class='website-rights'>STERNX © 2021</small>
    //       <div class='social-icons'>
    //         <Link
    //           class='social-icon-link facebook'
    //           to={{pathname:"https://www.facebook.com/sternxengineering"}}
    //           target='_blank'
    //           aria-label='Facebook'
    //         >
    //           <i class='fab fa-facebook-f' />
    //         </Link>
            
    //         <Link
    //           class='social-icon-link youtube'
    //           to={{pathname:"https://www.youtube.com/channel/UCc0DmPIO-S5TGd4m4VzdPJw"}}
    //           target='_blank'
    //           aria-label='Youtube'
    //         >
    //           <i class='fab fa-youtube' />
    //         </Link>
    //         <Link
    //           class='social-icon-link twitter'
    //           to='/'
    //           target='_blank'
    //           aria-label='Twitter'
    //         >
    //           <i class='fab fa-twitter' />
    //         </Link>
    //         <Link
    //           class='social-icon-link twitter'
    //           to={{pathname:"https://www.linkedin.com/company/76945900/admin/"}}
    //           target='_blank'
    //           aria-label='LinkedIn'
    //         >
    //           <i class='fab fa-linkedin' />
    //         </Link>
    //       </div>
    //     </div>
      
    // </div>

  );
}

export default Footer;
