//creating a functional export component
import React, { useState,useEffect } from "react";
import { Link } from "react-router-dom";
import { Button } from "../Button/Button";
import "./Navbar.css";
import { HashLink } from "react-router-hash-link";

function Navbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);
  const handleClick = () => setClick(!click);

  const closeMobileMenu = () => setClick(false);
  const showButton = () => {
    if (window.innerWidth <= 500) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener("resize", showButton);
  return (
    <>
      <nav className="navbar">
        <div className="navbar-container">
          {/* This is the Sternx Name that links to the homepage*/}
          <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
            <img src="logo_transparent.png" alt="" width="6%" height="6%" />
            STERNX
          </Link>

          {/* The menu bar icon  */}
          <div className="menu-icon" onClick={handleClick}>
            <i className={click ? "fas fa-times" : "fas fa-bars"} />
          </div>
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            {/**Home button Creation */}
            <li className="nav-items">
              {/* <Link to="/" className="nav-links" onClick={closeMobileMenu}>
                Home
              </Link> */}
              <HashLink
                smooth
                to="/#home"
                className="nav-links"
                onClick={closeMobileMenu}
              >
                Home
              </HashLink>
            </li>

            <li className="nav-items">
              <HashLink
                smooth
                to="/#aboutus"
                className="nav-links"
                onClick={closeMobileMenu}
              >
                About Us
              </HashLink>
            </li>

            <li className="nav-items">
              <HashLink
                smooth
                to="/#services"
                className="nav-links"
                onClick={closeMobileMenu}
              >
                Our Scope
              </HashLink>
            </li>

            {/* <li className="nav-items">
              <Link to="/works" className="nav-links" onClick={closeMobileMenu}>
                Works
              </Link>
            </li> */}

            <li className="nav-items">
              <Link to="/blogs" className="nav-links" onClick={closeMobileMenu}>
                Blogs
              </Link>
            </li>
            <li>
              {button && <Button buttonStyle="btn--outline">Contact Us</Button>}  
            </li>
          </ul>
          
        </div>
      </nav>
    </>
  );
}

export default Navbar;
