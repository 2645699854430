import React from 'react';
import "./Footer2.css";
import FooterCol from './FooterCol';
import Wave from 'react-wavify'

function Footer2() {
    return (
        <>
            <div className="footer-container2">
            <Wave 
                fill='#000'
                paused={false}
                options={{
                    height:80,
                    amplitude:40,
                    speed:0.3,
                    points:5
                }}
            />
            </div>
            
            <div className="footer__container">
                <div className="footer__col1">
                    <h1 className="footer__col1__title">About Us</h1>
                    <p className="footer_description">We are a software development team in Sri Lanka having over two years of experience in the field. Our focus is to understand the unique requirements of each client and meet them with their expected product while ensuring customer satisfaction. Our team with excellent problem-solving skills and commitment towards the end goal is here to steer you to the software solution that you need to digitalize your business entities with the updating cutting edge technologies.
</p>
                </div>
                <div className="footer__col2">
                    <FooterCol
                    heading = 'Quick Links'
                    links= {
                        [
                            {
                                title:'Home',
                                path: '/',
                                type: 'Link'
                            },
                            {
                                title:'About Us',
                                path: '/aboutus',
                                type: 'Link'
                            },
                            {
                                title:'Our Scope',
                                path: '/ourscope',
                                type: 'Link'
                            },
                            {
                                title:'Blogs',
                                path: '/blogs',
                                type: 'Link'
                            },

                        ]
                    }/>
                </div>
                <div className="footer__col3">
                    <FooterCol
                    heading = 'Contact'
                    links= {
                        [
                            {
                                title:'Email - sternxengineering@gmail.com',
                                path: '/',
                                
                            },
                            {
                                title:'Address',
                                path: '/aboutus',
                                
                            }

                        ]
                    }
                    />
                </div>
                <div className="footer__col4">
                    <FooterCol
                    
                    heading = 'Social Links'
                    links= {
                        [
                            {
                                title:'Facebook',
                                path: '/',
                                
                            },
                            {
                                title:'LinkedIn',
                                path: '/aboutus',
                                
                            },
                            {
                                title:'Email',
                                path: '/ourscope',
                                
                            },
                            {
                                title:'Blogs',
                                path: '/blogs',
                                
                            },

                        ]
                    }
                    
                    />
                </div>
            </div>
    	</>
    )
}

export default Footer2
