import React from "react";
import "../../App.css";
import CardItem from "../Card/CardItem";
import "../Card/Cards.css";
export default function Services() {
  return (
    <>
      {/* <div id="services" className="container"> */}
      <div id="services" className="services-section">
        <h1>Our Scope</h1>

        <hr />
        <br></br>

        <ul className="cards__items">
          <CardItem
            src="images/img-services-1/img1.png"
            text="Mobile Development"
            label=""
            description="Smartphones have become the most common digital assistant for humans. We will help your ideas to reach customers with the fast-growing mobile application trends. A mobile application compatible with both Android and iOS operating systems will be delivered to you with user attractive features and optimized functionalities."
            //path="/detailed-services"
          />
          <CardItem
            src="images/img-services-1/img2.png"
            text="Website Development"
            label=""
            description="Let your business be accessible to anyone, anywhere, anytime with a unique and attractive website. Our modern responsive web designs and up to date web development capabilities are delivered to you with expert services. A creative website helps to establish the credibility of your business with the help of the latest technologies. 
            "
            //path="/detailed-services"
          />
          <CardItem
            src="images/img-services-1/img3.png"
            text="API Development"
            label=""
            description="API, Application Programming Interface will help other software applications to communicate with your software app or platform while establishing better services.We develop API services according to your requirenments to simplify and speed up your existing software service, by acting as a layer in between software and hiding complexities within individual services."
            //path="/detailed-services"
          />
          <CardItem
            src="images/img-services-1/img4.png"
            text="Game Development"
            label=""
            description="The gaming industry plays a major role in technology because of its impact on advancing entertainment and culture. Whether you want to create a game as a mobile application or a web application with enhanced features, we will bring your game idea to the front of worldwide gamers with interactive features and an exciting gaming experience."
            //path="/detailed-services"
          />
        </ul>
        <ul className="cards__items">
          <CardItem
            src="images/img-services-1/img5.png"
            text="UI / UX Services"
            label=""
            description="Well made and balanced UI/UX designs helps users to easily interact with your services while enhancing customer satisfaction and loyalty.  We will bring the best-suited UI/UX design to you featuring the usability and accessibility of your service."
            //path="/detailed-services"
          />
          <CardItem
            src="images/img-services-1/img6.jpg"
            text="AI and Machine Learning"
            label=""
            description="Integrate AI and Machine Learning technologies into your business to obtain actionable insights and drive critical decisions. We will give life to your innovative products and services ideas with these emerging technologies. Migrating your business into  AI and ML-enabled systems will bring it to the advancement in the industry."
            //path="/detailed-services"
          />
          <CardItem
            src="images/img-services-1/img7.png"
            text="Data Analysis"
            label=""
            description="Your business data in itself is merely facts and figures. We are here to help you in exploring your data in order to identify your business insights. Our data analysis consists of organizing,  interpreting, illustrating, evaluating and presenting the data with a systematical approach."
            //path="/detailed-services"
          />
          <CardItem
            src="images/img-services-1/img8.png"
            text="Cloud Computing"
            label=""
            description="Cloud computing services are there to provide computing services for your business from applications to storage and processing power. The on-demand availability enables your business to communicate and share more easily outside of the traditional methods. We are here to assist you in designing, developing and managing AWS, Google Cloud and Azure cloud computing services in your production environment."
            //path="/detailed-services"
          />
        </ul>
      </div>
    </>
  );
}
