import "../../App.css";
import HeroSection from "../HeroSection/HeroSection";

import React from "react";
import AboutUs from "./AboutUs";
import Services from "./OurServices";
// import Works from "./Works";
import Footer from "../Footer/Footer";
import Technologies from "./Technologies/Technologies";
import Navbar from "../NavBar/Navbar";
import MessengerCustomerChat from "react-messenger-customer-chat";
// import Blogs from "./Blog/Components/Blogs";
import MailerComponent from "./../ContactUs/MailerComponent"
import Footer2 from "../Footer/Footer2";

function Home() {
  return (
    <div className="container">
      <Navbar />
      <HeroSection />
      <AboutUs />
      <Services />
      {/* <Works /> */}
      {/* <Blogs/> */}
      <Technologies />
      <MailerComponent/>
      <Footer2/>
      <Footer/>
      <MessengerCustomerChat
        pageId="104623278386310"
        appId="3142755355947250"
      />
    </div>
  );
}

export default Home;
